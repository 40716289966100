<template>
    <div class="page-sms-gateway-send-message">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Send Message</h1>

                    <div class="board">
                        <app-loader v-if="loading" />

                        <app-error v-model="errors.show" :message="errors.message" />

                        <div class="form-container">
                            <div class="form-group">
                                <div class="form-controls">
                                    <app-input
                                        v-model="To"

                                        label="To"

                                        :required="true"

                                        :mask="phone_number_mask"

                                        :error="errors.fields.To"
                                        :disabled="loading"

                                        @change="errors.fields.To = null"
                                    />
                                </div>

                                <div class="form-controls">
                                    <app-input
                                        v-model="From"

                                        label="From"

                                        :required="true"

                                        :mask="phone_number_mask"

                                        :error="errors.fields.From"
                                        :disabled="loading"

                                        @change="errors.fields.From = null"
                                    />
                                </div>

                                <div class="form-controls">
                                    <app-checkbox v-model="ReportDelivery" :disabled="loading">Report delivery</app-checkbox>
                                </div>

                                <div class="form-controls">
                                    <app-textarea
                                        v-model="Text"

                                        label="Text"

                                        :required="true"

                                        :error="errors.fields.Text"
                                        :disabled="loading"

                                        @change="errors.fields.Text = null"
                                    />
                                </div>

                                <div class="form-controls">
                                    <app-dropdown-select
                                        v-model="Priority"
                                        :options="priorities"

                                        key-value="value"
                                        key-title="title"

                                        label="Priority"

                                        :error="errors.fields.Priority"
                                        :disabled="loading"

                                        @change="errors.fields.Priority = null"
                                    />
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-primary btn-small" @click="send">Send</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appTextarea from '@/components/app-textarea'
import appCheckbox from '@/components/app-checkbox'
import appDropdownSelect from '@/components/app-dropdown-select'

import SMSGatewayValidator from '@/validators/sms-gateway-validator'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appError,
        appInput,
        appTextarea,
        appCheckbox,
        appDropdownSelect,
    },

    data() {
        return {
            To: null,
            From: null,
            ReportDelivery: false,
            Text: null,
            Priority: null,

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.priorities.length > 0) {
                this.Priority = this.priorities[this.priorities.length - 1].value
            }
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = {
                To:   { rule: 'PhoneNumber', message: 'Please, enter valid phone number'       },
                From: { rule: 'From',        message: 'Please, enter from who will be message' },
                Text: { rule: 'Text',        message: 'Please, write a message'                },
            }

            for (const key in fields) {
                if (SMSGatewayValidator.isRuleExists(fields[key].rule)) {
                    if (SMSGatewayValidator.isInvalid(fields[key].rule, this[key], fields[key].message)) {
                        this.errors.fields[key] = SMSGatewayValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        send() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    To: this.To,
                    From: this.From,
                    ReportDelivery: this.ReportDelivery,
                    Text: this.Text,
                    Priority: this.Priority,
                }

                console.log('params', params)

                this.$store.dispatch('api_smsgw/SendMessage', params)
                    .then(() => {
                        this.errors.show = false
                        this.errors.message = ''

                        this.loading = false

                        this.$store.dispatch('addToast', {
                            message: 'Your message was successfully sent',
                            delay: 5000,
                        })
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },
    },

    computed: {
        phone_number_mask() {
            return {
                regex: String.raw`^\+?\d{9,12}$`
            }
        },

        priorities() {
            return [
                {
                    value: 'LOW',
                    title: 'Low',
                },
                {
                    value: 'MEDIUM',
                    title: 'Medium',
                },
                {
                    value: 'HIGH',
                    title: 'High',
                },
                {
                    value: 'AUTO',
                    title: 'Auto',
                },
            ]
        },
    },
}
</script>

<style lang="scss">
.page-sms-gateway-send-message {
    padding-bottom: 80px;

    h1 {
        &.heading {
            margin-bottom: 40px;
        }
    }

    .board {
        position: relative;

        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        .app-error {
            margin-bottom: 24px;
        }

        .form-container {
            margin-bottom: 24px;

            .form-group {
                max-width: 50%;

                .form-controls {
                    margin-bottom: 30px;

                    .app-textarea {
                        height: 106px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-sms-gateway-send-message {
        .board {
            .form-container {
                .form-group {
                    max-width: 100%;
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-sms-gateway-send-message {
        padding-bottom: 64px;

        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .board {
            padding: 24px 8px;

            .form-container {
                .form-group {
                    .form-controls {
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }
}
</style>