import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    PhoneNumber: {
        name: 'Phone number',
        required: true,
        replaces: [
            {
                format: /^\+?\d{0,4}\(?\d{1,4}\)?[-\s\d]*$/,
                pattern: /[^\d]/g,
                replace: '',
            },
        ],
        format: /^\+?\d{9,12}$/,
    },

    Text: {
        name: 'Text',
        trim: true,
        required: true,
    },

    From: {
        name: 'From',
        required: true,
    },
})